import { css, html, LitElement } from "lit"
import { createRef, ref } from "lit/directives/ref.js"
import Hls from "hls.js"
import Plyr from "plyr"
import { debounce } from "#js/components/utils"
import { fetchJSON } from "#js/components/http"
import styles from "plyr/dist/plyr.css"

export class VideoPlayer extends LitElement {
  static get properties() {
    return {
      videoUrl: { type: String },
      iconUrl: { type: String },
      currentTime: { type: Number },
      trackingUrl: { type: String },
    }
  }

  constructor() {
    super()
    this.iconUrl = globalThis.svgSprite
  }

  static styles = [
    styles,
    css`
    :root {
      --plyr-color-main: var(--brand-color);
    }
  `,
  ]

  videoRef = createRef()

  firstUpdated() {
    const plyr = new Plyr(
      this.videoRef.value,
      {
        debug: globalThis.DEBUG,
        autoplay: true,
        iconUrl: this.iconUrl,
        loadSprite: this.loadSprite,
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "duration",
          "mute",
          "volume",
          "captions",
          "settings",
          "airplay",
          "fullscreen",
        ],
        captions: {
          language: globalThis.language,
        },
      },
    )

    // Set the current time of the video after it has loaded
    plyr.once("loadedmetadata", (event) => {
      plyr.currentTime = this.currentTime
    })

    plyr.on("timeupdate", (event) => {
      if (event.detail.plyr.playing) {
        this.trackCurrentTime(Math.floor(event.detail.plyr.currentTime))
      }
    })

    if (Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(this.videoUrl)
      hls.attachMedia(this.videoRef.value)
    }
  }

  trackCurrentTime(currentTime) {
    if (currentTime % 10 === 0) {
      this.debouncedFetch(this.trackingUrl, {
        method: "PUT",
        credentials: "same-origin",
        body: JSON.stringify({
          currentTime,
        }),
      }).then(
        (response) => console.debug(`Tracking video time: ${currentTime}`),
      )
    }
  }

  debouncedFetch = debounce(fetchJSON, 1000)

  getCaptions() {
    return {
      en: this.videoUrl.replace(".m3u8", "-lang-en.vtt"),
      de: this.videoUrl.replace(".m3u8", "-lang-de.vtt"),
    }
  }

  render() {
    const captions = this.getCaptions()
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}"/>
      <video playsinline controls ${ref(this.videoRef)} crossorigin="anonymous">
        <source src="${this.videoUrl}" type="video/mp4" />
        <track kind="captions" label="English" src="${captions.en}" srclang="en" />
        <track kind="captions" label="Deutsch" src="${captions.de}" srclang="de" />
      </video>
    `
  }
}

globalThis.customElements.define("ui-video", VideoPlayer)
